






































import KTextInput from '@/@core/components/input/KTextInput.vue';
import KHamburgerMenu from '@/@core/components/navigation/KHamburgerMenu.vue';
import KCard from '@/@core/components/other/KCard.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KHeaderBackButton from '@/@core/components/page/KHeaderBackButton.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KText from '@/@core/components/typography/KText.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import {
  defineComponent,
  onMounted,
  Ref,
  ref
} from '@vue/composition-api';
import IndustryForm from '../../ui/IndustryForm.vue';
import { useIndustryStore } from '../../services/industryStore';
import { useIndustryStore as newStore } from '../../services/store';
import router from '@/router';
import { useAuthStore } from '@/modules/auth/services/store';
import { useUiStateStore } from '@/@core/services/uiStateStore';

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KText,
    KPageBody,
    KWrapper,
    KCard,
    KHamburgerMenu,
    KTextInput,
    KHeaderBackButton,
    IndustryForm
  },
  name: 'IndustryFormPage',
  props: {
    id: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const reRenderCount = ref(0);
    const isLoading = ref(false);
    const formData: Ref<any> = ref({
      // General Information
      name: '',
      address: '',
      geolocation: {
        latitude: null,
        langitude: null
      },
      businessEntity: '',
      npwp: '',
      ownerName: '',
      inChargeName: '',
      phoneNumber: '',
      products: [
        {
          name: '',
          capacityPerMonth: null,
          pricePerUnit: null,
          productionValue: null,
          marketingArea: ''
        }
      ],
      kbli: '',
      permissions: [
        {
          value: '',
          type: '',
          date: null,
          agency: ''
        }
      ],
      socialMedias: [
        {
          type: 'instagram',
          value: ''
        },
        {
          type: 'facebook',
          value: ''
        },
        {
          type: 'website',
          value: ''
        }
      ],
      employees: {
        male: null,
        female: null
      },

      //
      investmentValues: [
        // {
        //     subject: 'Tanah',
        //     value: 'land',
        //     qty: null,
        //     unit: '',
        //     pricePerUnit: null,
        //     acquisitionValue: null
        // },
      ],
      materials: [
        //
      ],
      costs: {
        rawMaterialPerMonth: null,
        additivesMaterialPerMonth: null,
        auxiliaryMaterialPerMonth: null,
        operationalsPerMonth: null,
        laborWagesPerMonth: null,
        othersPerMonth: null
      },
      additionals: {
        // qualitySystem: '',
        // companyHrCompetence: '',
        // trainingType: '',
        // problemsEncountered: '',
        // suggestion: '',
      }
    });

    const industryStore = useIndustryStore();
    const store = newStore();

    const onSubmit = async () => {
      isLoading.value = true;

      await store.postIndustry(formData.value);
      useUiStateStore().createSnackbar({
        message: `Industri ${formData.value.name} berhasil ditambahkan.`
      });
      // industryStore.createIndustry(formData.value)
      isLoading.value = false;
      router.push('/app/industri');
    };

    function dummyData() {
      const dummy = {
        name: 'Lina Sasirangan ',
        address: 'Jl. Seberang Masjid Rt. 05 No. 08',
        geolocation: {
          latitude: -3.310882,
          longitude: 144.544871
        },
        businessEntity: 'PO',
        npwp: '92.245.220.6-736.000',
        ownerName: 'Rusmalina',
        inChargeName: 'Rusmalina',
        phoneNumber: '081348231197',
        products: [
          'Kain Sasirangan',
          'Baju Kaos',
          'Jilbab Sasirangan'
        ],
        classification: '01112 - PERTANIAN GANDUM',
        permissions: [
          {
            value: 'IDM0062825',
            type: 'Sertifikat Merek',
            date: new Date('2017/01/09').toISOString().slice(0, 10),
            agency: 'Menteri Hukum dan Ham'
          },
          {
            value: '0276010041075',
            type: 'NIB',
            date: new Date('2020/10/07').toISOString().slice(0, 10),
            agency: 'Badan Koordinasi Penanaman Modal'
          },
          {
            value: 'IUMK/86/BTH/2020',
            type: 'IUMK',
            date: new Date('2020/09/01').toISOString().slice(0, 10),
            agency: 'Dinas Kecamatan Banjarmasin Tengah'
          }
        ],
        socialMedias: [
          {
            type: 'instagram',
            value: ''
          },
          {
            type: 'facebook',
            value: ''
          },
          {
            type: 'website',
            value: ''
          }
        ],
        employees: {
          male: 1,
          female: 2
        }
      };

      const newDummy = {
        name: 'Test A',
        address: 'Jl. ABC',
        geolocation: {
          latitude: -3.310882,
          longitude: 144.544871
        },
        businessEntity: 'Koperasi',
        npwp: '101010101010101',
        ownerName: 'An Owner',
        inChargeName: 'An in charge name',
        phoneNumber: '0895634775903',
        products: [
          {
            name: 'Product A',
            capacityPerMonth: 1000,
            capacityPerYear: 12000,
            pricePerUnit: 5000,
            productionValue: 60000000,
            marketingArea: 'Lokal'
          },
          {
            name: 'Product B',
            capacityPerMonth: 10,
            capacityPerYear: 120,
            pricePerUnit: 100000,
            productionValue: 12000000,
            marketingArea: 'Lokal'
          }
        ],
        permissions: [
          {
            value: 'IDM0062825',
            type: 'Sertifikat Merek',
            date: new Date('2017/01/19').toISOString().slice(0, 10),
            agency: 'Menteri Hukum dan Ham'
          },
          {
            value: '0276010041075',
            type: 'NIB',
            date: new Date('2020/10/17').toISOString().slice(0, 10),
            agency: 'Badan Koordinasi Penanaman Modal'
          },
          {
            value: 'IUMK/0102/BTH/2016',
            type: 'Surat Izin Usaha Mikro dan Kecil',
            date: new Date('2016/10/31').toISOString().slice(0, 10),
            agency: 'Kecamatan Banjarmasin Tengah'
          }
        ],
        classification: '01112 - PERTANIAN GANDUM',
        socialMedias: [
          {
            type: 'instagram',
            value: 'testa'
          }
        ],
        employees: {
          male: 32,
          female: 10
        },
        commodities: ['Industri Pangan', 'Industri Sandang'],
        additionals: {
          suggestion: 'suggestion example'
        },
        // @ts-ignore
        surveyBy: useAuthStore().user?.id,
        isVerified: false,
        investmentValues: [
          {
            subject: 'Tanah',
            value: 'land',
            qty: 15,
            unit: 'hektar',
            pricePerUnit: 10000,
            acquisitionValue: 150000
          },
          {
            subject: 'Bangunan',
            value: 'building',
            qty: 1,
            unit: 'unit',
            pricePerUnit: 5000000,
            acquisitionValue: 5000000
          },
          {
            subject: 'Mesin dan peralatan',
            value: 'equipments',
            data: [
              {
                subject: 'Mesin A',
                qty: 2,
                unit: 'unit',
                pricePerUnit: 1500000,
                acquisitionValue: 3000000
              },
              {
                subject: 'Alat B',
                qty: 1,
                unit: 'unit',
                pricePerUnit: 2000000,
                acquisitionValue: 2000000
              }
            ]
          },
          {
            subject: 'Sarana dan prasarana',
            value: 'facilities',
            data: [
              {
                subject: 'Fasilitas A',
                qty: 4,
                unit: 'unit',
                pricePerUnit: 2000000,
                acquisitionValue: 8000000
              },
              {
                subject: 'Prasarana B',
                qty: 1,
                unit: 'unit',
                pricePerUnit: 3000000,
                acquisitionValue: 3000000
              }
            ]
          }
        ],
        materials: [
          {
            value: 'raw-materials',
            subject: 'Bahan Baku',
            data: [
              {
                subject: 'Bahan Baku A',
                needsPerMonth: 100,
                pricePerUnit: 10000,
                totalNeedsPerMonth: 1000000,
                totalNeedsPerYear: 12000000,
                materialOrigin: 'Lokal'
              },
              {
                subject: 'Bahan Baku B',
                needsPerMonth: 50,
                pricePerUnit: 400000,
                totalNeedsPerMonth: 20000000,
                totalNeedsPerYear: 240000000,
                materialOrigin: 'Lokal'
              }
            ]
          },
          {
            value: 'additives-materials',
            subject: 'Bahan Tambahan',
            data: [
              {
                subject: 'Bahan Tambahan C',
                needsPerMonth: 6,
                pricePerUnit: 1200000,
                totalNeedsPerMonth: 7200000,
                totalNeedsPerYear: 86400000,
                materialOrigin: ''
              }
            ]
          },
          {
            value: 'auxiliary-materials',
            subject: 'Bahan Penolong',
            data: [
              {
                subject: 'Bahan Penolong D',
                needsPerMonth: 12,
                pricePerUnit: 200000,
                totalNeedsPerMonth: 2400000,
                totalNeedsPerYear: 28800000,
                materialOrigin: 'Lokal'
              }
            ]
          }
        ],
        costs: {}
      };

      formData.value = {
        ...formData.value,
        ...newDummy
      };

      reRenderCount.value++;
    }

    onMounted(async () => {
      const isEdit = props.id;

      if (isEdit) {
        const response: any = await store.getOneIndustry(
          props.id as string
        );

        if (response) {
          formData.value = {
            ...formData.value,
            ...response.industry
          };

          reRenderCount.value++;
        }
      }
    });

    return {
      isMobile,
      formData,
      onSubmit,
      isLoading,
      dummyData,
      reRenderCount
    };
  }
});
